import { FormItem } from 'element-ui';
<template>
  <div class="Console">
    <h2>控制台</h2>
    <div class="wrap">
      <div class="menu-item" v-for="item in menuData" :key="item.id" @click="goto(item.code)">
        <span :class="'iconfont icon-'+item.code"></span>
        <span class="chn">{{item.name}}</span>
      </div>
    </div>
  </div>
</template>
    
<style lang="less" scoped>
.Console {
  padding: 20px;
  .wrap {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 60px;
    padding-bottom: 60px;


    .menu-item {
      margin-right: 60px;
      margin-top: 60px;
      width: 300px;
      height: 200px;
      background-color: #ecf5ff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      color: #409eff;
      border: 1px solid #b3d8ff;
      box-sizing: border-box;
      
      .iconfont {
        font-size: 70px;
      }
      .chn {
        margin-top: 10px;
        font-size: 26px;
        font-weight: bold;
      }
    }
    .menu-item:hover {
      background-color: #409EFF;
      color: #FFFFFF;

      box-shadow: 0px 0px 20px #9b9b9b;
      transform: scale(1.1);
    }

  }
}
</style>
    
<script>
export default {
  name: "Console",
  components: {},
  data() {
    return {
      menuData: []
    };
  },
  computed: {
    
  },
  mounted() {
    this.onGetMenuData()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取用户菜单
    onGetMenuData: function () {
      var arr = JSON.parse(localStorage.getItem("menuData"))
      arr.forEach(item => {
        if (item.hasOwnProperty("children")) {
          item.children.forEach(subItem => {
            this.menuData.push(subItem)
          })
        } else {
          this.menuData.push(item)
        }
      });
    },
  },
};
</script>
    